<template>
  <div id="conorderbox">
    <div class="hadheight"></div>
    <Header />
    <div class="conorderbox-sec1">
        <div class="basecont">
            <div class="con_nav">
                <router-link to="/my/myorder" class="returngt">
                    <i><img src="../../assets/images/returniconig1.png" alt=""></i>
                    <span>返回订单</span>
                </router-link>
            </div>
            <div class="cont">
                <div class="logo">
                    <router-link to="/">
                        <img src="../../assets/images/gouwuliuimg1.png" alt="">
                    </router-link>
                </div>
                <div class="f_font">
                    <template v-for="(item,index) in orderNav">
                        <div 
                         :class="{item:true,on:index<=orNavIn}" 
                         :key="index">
                            <i><img :src="item.icon" :alt="item.name"></i>
                            <div class="tit">{{item.name}}</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="conorderbox-sec2 base150">
        <div class="basecont">
            <div class="cont">
                <div class="lt">
                    <div class="conor_tail" v-if="orderTail.length!=0">
                        <div class="conor_tit basefont30">订单跟踪</div>
                        <div class="fztit">
                            <div class="tit">顺丰快递</div>
                            <div class="hao">{{orderTail.mailNo}}</div>
                        </div>
                        <div class="fzlist">
                            <template v-for="(item, index) in orderRoutesReverse">
                                <div 
                                :class="{f_li:true ,on: index==0}" 
                                :key="index">
                                    <div class="fzzi">
                                        <div class="f_font">
                                            <div class="f_ti1">{{item.remark}}</div>
                                            <div class="time">{{item.acceptTime.substring(0,16)}}</div>
                                        </div>
                                        <div class="text">
                                            {{item.remark}}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="conor_form">
                        <div class="conor_tit basefont30">收货人信息</div>
                        <template v-if="baocun==true?false:true">
                            <div class="f_address">
                                    <div class="item on"  v-if="orSteta>2 && orSteta<5">
                                        <a href="javascript:;" class="list">
                                            <div class="tit">
                                                <strong>{{myOrderParams.consignee}}</strong>
                                                <strong>{{myOrderParams.mobile.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2")}}</strong>
                                            </div>
                                            <div class="text">
                                                {{myOrderParams.province}} {{myOrderParams.district}} {{myOrderParams.city}}
                                            </div>
                                        </a>
                                    </div>
                                    <template 
                                        v-for="(item, index) in address">
                                        <div 
                                            :class="{item: true,on: index === addIndex}"  
                                            :key="index">
                                            <a href="javascript:;" @click="addSelect(item,index)" class="list">
                                                <div class="tit">
                                                    <strong>{{item.receiveName}}</strong>
                                                    <strong>{{item.phone.replace(/(\d{3})\d{4}(\d{4})/,"$1****$2")}}</strong>
                                                </div>
                                                <div class="text">
                                                    {{item.province}} {{item.region}} {{item.city}}
                                                </div>
                                                <div class="but" v-show="orSteta==1 || orSteta==7 || orSteta == 100">
                                                    <div class="f_but" @click.stop="removeAddress(index)">删除</div>
                                                    <div class="f_but" @click.stop="compileShow(item)">编辑</div>
                                                </div>
                                            </a>
                                        </div>
                                    </template>

                                    <div class="item active" v-show="orSteta==1 || orSteta==7 || orSteta == 100">
                                        <a href="javascript:;" @click="addSite()" class="list">
                                            <div class="tit">
                                                <strong>站位</strong>
                                                <strong>站位</strong>
                                            </div>
                                            <div class="text">
                                            站位
                                            </div>
                                            <div class="but">
                                                <div class="f_but">站位</div>
                                                <div class="f_but">站位</div>
                                            </div>
                                            <div class="f_text">+ 新增地址</div>
                                        </a>
                                    </div>
                            </div>
                        </template>
                        <!-- 添加地址 -->
                        <transition name="fade">
                            <!-- 
                            添加地址标题
                            title 参数类型字符串String
                            togglecompile 参数类型Number 为0的时候代表是添加收货地址  为1的时候是编辑收货地址
                            arrayCom 参数类型object 当为添加收货地址的时候为空  编辑的时候传递数据
                            altype  参数类型Boolean  为true的时候  是弹窗形式  否则是固定的形式
                            updataAdd 函数  返回值为true  要更新数据因为这是表示已经编辑或者保存了地址
                            adFadeOut 函数  返回为false  表示隐藏菜单
                            -->
                            <AddressAltForm 
                            :title = "addressTitle"
                            :togglecompile = "compile"
                            :arrayCom = "arrayCom"
                            :altype = "altype"
                            @updataAdd = "refresh"
                            @adFadeOut = "addressHide"
                            @addSub = "addSub"
                            v-show="adFade" 
                        ></AddressAltForm>
                        </transition>
                    </div>
                    <div class="conor_message">
                        <div class="conor_tit">
                            <div class="tit basefont30">确认商品信息</div>
                            <div class="jian">{{myOrderParams.orderItemInfoList.length}}件商品</div>
                        </div>
                        <div class="f_jut">
                            <div class="item" v-for="(item,index) in myOrderParams.orderItemInfoList" :key="index">
                                <div class="img">
                                    <img :src="item.skuDefaultImg" :alt="item.skuName">
                                </div>
                                <div class="font">
                                    <div class="tit">{{item.skuName}}</div>
                                    <div class="text">
                                        <p>{{item.skuDesc}} <span>数量：{{item.buyNum}}</span></p>
                                        <p>零售价：{{item.retailPrice}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="conor_word" v-show="orSteta==1 || orSteta==7 || this.orSteta==100">
                        <div class="conor_tit">
                            <div class="f_tit basefont30">订单留言/备注</div>
                            <div class="f_text">如有特殊要求，请留言，客服会第一时间联系您的；或者直接联系在线客服</div>
                        </div>
                        <div class="f_jut">
                            <textarea name="ordermessage" v-model="ordermessage"></textarea>
                            <div class="zi">
                                <span>{{ordermessage.length}}/200</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gt">
                    <div class="f_nei">
                        <div class="tit">订单详情</div>
                        <div class="mess">
                            <div class="li">
                                <span>商品总价</span>
                                <span>¥ {{myOrderParams.orderPrice}}</span>
                            </div>
                            <div class="li">
                                <span>优惠券</span>
                                <span>¥ {{myOrderParams.couponPrice}}</span>
                            </div>
                            <div class="li">
                                <span>消费税</span>
                                <span>¥ {{myOrderParams.exciseTax}}</span>
                            </div>
                            <!-- <div class="li">
                                <span>运费</span>
                                <span>¥ {{myOrderParams.couponPrice}}</span>
                            </div> -->
                        </div>
                        
                        <div class="zong">
                            <span>总计</span>
                            <div class="jia">¥ {{myOrderParams.actualPrice}}</div>
                        </div>
                        <!-- 待支付  或者  取消的时候才会显示 -->
                        <div v-show="orSteta==1 || orSteta==7 || this.orSteta==100">  
                            <div class="fzdiscount">
                                <div class="fz_tit" @click="toggleCoupon($event)">
                                    <div class="name">
                                        {{coupon.length>0?'优惠券':"没有可用的优惠券"}}
                                    </div>
                                    <div class="but" v-show="coupon.length>0">
                                        <span>{{couponName}}</span>
                                        <i>
                                            <img src="../../assets/images/xiangyouimg1.png" alt="">
                                        </i>
                                    </div>
                                </div>
                                <div class="fhover1" v-if="coupon.length>0">
                                    <div class="fnei">
                                        <div class="ft1" 
                                            v-for="(item,index) in coupon"
                                            @click="couponSelect($event,index)"
                                            :key="index">
                                                {{item.couponName}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="{zheng:true,on: xie}">
                                <i @click="xie=!xie"></i>
                                <span>我同意<router-link :to="{path:'/policy',query: {title:'注册协议'}}">《注册协议》</router-link>和<router-link :to="{path:'/policy',query: {title:'隐私政策'}}">《隐私政策》</router-link></span>
                            </div>
                            <a href="javascript:;" @click="subCon()" class="hm-but f_but">
                                <span>提交订单</span>
                                <i>
                                    <img src="../../assets/images/jiantouyo1.png" >
                                    <img src="../../assets/images/jiantouyo2.png" >
                                </i>
                            </a>
                        </div>
                        
                    </div>
                </div>
            </div>    
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import AddressAltForm from '@/components/addressAltForm/AddressAltForm.vue';
import { forEach } from 'mathjs';

export default {
  components: {
    Header,
    Footer,
    AddressAltForm
  },
  data(){
    return {
        /* 表单标题 */
        addressTitle: "添加收货地址",
        address:[],
        compile: 0,
        arrayCom: {},
        altype: true,
        addIndex: -1,
      orNavIn: 1,
      orderNav:[
        {
          name: "加入购物车",
          icon: require("../../assets/images/gouwuicnimgf1.png")  
        },
        {
          name: "确认订单",
          icon: require("../../assets/images/gouwuicnimgf2.png")  
        },
        {
          name: "付款成功",
          icon: require("../../assets/images/gouwuicnimgf3.png")  
        },
      ],
      adFade:false,
      addsele: 2000,
      xie: false,
      baocun: false,
      ordermessage:"",
      shopmess:[],
      addressdizhi:[],
      orderTail: [],
      orderRoutesReverse:[],
      orSteta: '',
      coupon: [],
      couponName: "",
      myOrderParams: JSON.parse(localStorage.getItem("orderMessage")) || {}
    }
  },
  created(){
        /* 判断是否选择了优惠券 */
        this.myOrderParams.couponRecordId = undefined;
        this.myOrderParams.stetaor = true;

        this.orSteta = this.myOrderParams.orderStatus;

       if(this.orSteta>2 && this.orSteta<5){  //已发货  待签收
            // 物流查询
            this.$axios.post('/api2/portal/auth/order/sf/listOrderRoute',{
                orderId: this.myOrderParams.id
            }).then( res => {
                if(res.code == "000000"){
                    this.orderTail = res.data[0]; 
                }else{
                    this.$message({
                        message: res.message,
                        type: 'error',
                    });
                }
            } ).catch(error => {
                console.log(error);
            });
       }
       
       

  },
  mounted(){
    if(this.orSteta==1 || this.orSteta==7 || this.orSteta==100){  
        //待支付或者取消的时候才会执行
        /* 获取优惠券 */
        this.getMyCoupon();
        /* 获取地址 */
        this.getAddres(); 
    }
  },

  watch:{
    orderTail(qi,hou){  //翻转接口 
        this.orderRoutesReverse = qi.routes.reverse();
    },
    ordermessage(lstName,friName){
        if(this.ordermessage.length>200){
            this.ordermessage = friName;
        }
    }
  },
  methods: {
    addSelect(obj,_in){
        this.addIndex = _in;
        this.myOrderParams.province = obj.province;
        this.myOrderParams.city = obj.city;
        this.myOrderParams.district = obj.region;
        this.myOrderParams.consignee = obj.receiveName;
        this.myOrderParams.mobile = obj.phone;

        this.myOrderParams.stetaor = false;


        this.setLocalStorageData("orderMessage",this.myOrderParams);

        this.myOrderParams = JSON.parse(this.getLocalStorageData("orderMessage"));
    },
    proaltShow(){
        $(".proinfoAlt").fadeIn();
    },
    returncli:function(){  //返回上一页
        this.$router.back();
    },
    subCon:function(){ //提交订单
        if(this.addIndex==-1){
            this.$message({
                type: "info",
                message: "请选择收货信息！"
            });
            return false;
        }
        if(this.xie!=true){
            this.$message({
                type: "info",
                message: "请查看并勾选隐私政策和注册协议！"
            });
            return false;
        }
        let data = {
            actualPrice:this.myOrderParams.actualPrice,
            addressId: this.address[this.addIndex].id,
            couponRecordId: this.myOrderParams.couponRecordId,
            orderItemVOList: [],
            postscript: this.ordermessage
        };

        this.myOrderParams.orderItemInfoList.forEach(v => {
            data.orderItemVOList.push({
                skuId: v.skuId,
                buyNum: v.buyNum
            });
        });
        if(!this.myOrderParams.stetaor && this.myOrderParams.orderNo!=""){
            this.$axios.post("/api2/portal/auth/order/detail/insert",data).then(res => {
                if(res.code == "000000"){
                    this.$message({
                        message: "提交成功，去支付吧！",
                        type: 'success',
                    });
                    this.myOrderParams.stetao = true;
                    this.myOrderParams.orderNo = res.data.orderNo;
                    this.setLocalStorageData("orderMessage",this.myOrderParams);
                    this.$router.push({name:"Payment",params:{
                        orderNo: res.data.orderNo
                    }});
                }else{
                    this.$message({
                        message: res.message,
                        type: 'error',
                    });
                }
            }).catch( error => {
                console.log(error)
            });
        }else{
            this.$router.push({name:"Payment",params:{
                orderNo: this.myOrderParams.orderNo
            }});
        }

        
    },
    getMyCoupon(){
         /* 获取优惠券 useState = 2-已领取/可使用 3-已使用,4-已过期, */
        this.$axios.post('/api2/portal/auth/coupon/record/getMyCoupon',{
            useState: 2
        }).then( res => {
            if(res.code == "000000"){
                this.coupon = res.data;
                this.$nextTick(()=>{
                    this.couponName =  this.coupon.length + "张可用"
                });
                
            }else{
                this.$message({
                    message: res.message,
                    type: 'error',
                });
            }
            this.couponSelect();
        }).catch(error => {
            console.log(error);
        });
    },
    getAddres(){
        /* 获取收货地址 */
        
        this.$axios.post("/api2/portal/auth/user/address/myAddress").then(res=>{
            if(res.code == "000000"){
                let addSite = res.data;
                console.log(addSite);
                var receiveName = this.myOrderParams.consignee;
                var phone = this.myOrderParams.mobile;
                var province = this.myOrderParams.province
                var district = this.myOrderParams.district
                var city = this.myOrderParams.city
                if(receiveName){
                    addSite.forEach( (v,_in) => {
                        if(v.receiveName == receiveName && 
                            v.phone == phone &&
                            v.province == province &&
                            v.region == district &&
                            v.city == city
                        ){
                            console.log(_in);
                            this.addIndex = _in;
                        }
                    });
                }
                
                
                this.address = addSite;
            }else{
                this.$message({
                    message: res.message,
                    type: 'error',
                });
            }
            
        }).catch(error=>{
            console.log(error);
        });
    },
    /* 编辑收货地址 */
    compileShow(_val){
       this.altype = true;
        this.adFade = true;
        /* 编辑的时候  是1 */
        this.compile=1;
        this.arrayCom=_val;
        this.addressTitle="编辑收货地址";
    },
      /* 隐藏收货地址弹窗 */
    addressHide(_val){
        this.adFade = _val;
    },
      /* 添加收货地址 */
    addSite(){
        this.altype = false;
        this.baocun=true;
        this.adFade = true;
        /* 添加地址是0 */
        this.compile=0;
        this.arrayCom={};
        this.addressTitle="添加收货地址";
    },
    addSub(){
        if(this.compile==0){
            this.baocun=false;
        }
    },
    removeAddress:function(index){
         this.$axios.post('/api2/portal/auth/user/address/delete',{id:this.address[index].id}).then(res=>{
            if(res.code=="000000"){
                this.$message({
                    message: '删除成功！',
                    type: 'success'
                });
                this.address.splice(index,1);
            }else{
                this.$message({
                    message: '删除失败！',
                    type: 'error'
                });
            }
         }).catch(error=>{
            this.$message({
                    message: '接口异常！',
                    type: 'error'
                });
            console.log(error);
         });
    },
     /* 刷新地址 */
    refresh(_val){
        if(_val){
            this.getAddres();
        }
    },
    toggleCoupon(event){
        let e = event || e;
        let that = $(e.target);
        that.parents(".fzdiscount").toggleClass("on");
        that.parents(".fzdiscount").find(".fhover1").stop().slideToggle();
    },
    couponSelect(event,_in){ //优惠券筛选和价格计算
        let data = {
                orderItemVOList: []
        }
        if(_in!=undefined){
            data = {
                couponRecordId: this.coupon[_in].id,
                orderItemVOList: []
            }
        }
        this.myOrderParams.orderItemInfoList.forEach(v => {
            data.orderItemVOList.push({
                skuId: v.skuId,
                buyNum: v.buyNum
            });
        });
        this.$axios.post("/api2/portal/auth/order/detail/calculatePrice",data).then(res => {

            if(res.code=="000000"){
                this.myOrderParams.actualPrice = res.data.realPayAmount;
                this.myOrderParams.couponPrice = res.data.preferentialPrice || 0;
                this.myOrderParams.stetaor = false;
                this.myOrderParams.orderPrice = res.data.orderPrice || 0;
                
            
                
                if(_in!=undefined){
                    this.couponName = this.coupon[_in].couponName;
                    this.myOrderParams.couponRecordId = this.coupon[_in].id;
                }
                console.log(this.myOrderParams);
                this.setLocalStorageData("orderMessage",this.myOrderParams);
            }else{
                this.$message({
                    message: res.message,
                    type: 'error',
                });
            }
            if(this.coupon.length>0 && _in!=undefined){
                let e =  e || event;
                $(e.target).parents(".fhover1").stop().slideUp().parents(".fzdiscount").removeClass("on");
            }
            
            
        }).catch(error => {
            console.log(error);
        });
        
    },
    getLocalStorageData(name){
        let data = localStorage.getItem(name);
        return data;
    },
    setLocalStorageData(name,obj){
        let data = typeof obj == 'string'? obj:JSON.stringify(obj);
        localStorage.setItem(name,data);
    },
  }
}
</script>
<style lang="less">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>